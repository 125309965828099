import React from "react";
import Navbar from "./global-components/navbar-v2";
import PageHeader from "./global-components/page-header";
import ErrorPage from "./section-components/error";
import Footer from "./global-components/footer";

const Success = () => {
  return (
    <div>
      <Navbar />

      <ErrorPage type={"success"} />
      <Footer />
    </div>
  );
};

export default Success;
