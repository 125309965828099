import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  FacebookAuthProvider,
  signOut,
  sendEmailVerification,
} from "firebase/auth";
import { auth, db } from "../../firebase";
import { useHistory } from "react-router-dom";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import { addUserFirestore } from "../../helpers/addUserFirestore";
import { useDispatch } from "react-redux";
import { login } from "../../redux/slices/auth/authSlice";
import { createStripeCustomer } from "../../helpers/createStripeCustomer";
const provider = new GoogleAuthProvider();
const fbProvider = new FacebookAuthProvider();
export default function SignIn() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [details, setDetails] = useState({
    email: "",
    password: "",
  });
  function handleLogin() {
    if (details.email !== "" && details.password !== "") {
      signInWithEmailAndPassword(auth, details.email, details.password)
        .then(async (userCredential) => {
          // Signed in
          const user = userCredential.user;
          if (user.emailVerified) {
            const userDoc = await getDoc(doc(db, "users", user.uid));
            const userType = userDoc.data().user?.type;

            if (userType === "admin") {
              alert("Admin user detected. Login not allowed.");
              // Sign out the user to prevent them from accessing the app
              await signOut(auth);
              return;
            }

            // User is not an admin, continue with login
            console.log("Regular user logged in.");
            addUserFirestore(user);
            // createStripeCustomer(user);
            dispatch(login(user));
            history.push("/");
          } else {
            sendEmailVerification(auth.currentUser)
              .then((res) => {
                console.log(res);
                alert(
                  `Verification email has been sent to you.\nPlease check your email inbox.`
                );
              })
              .catch((er) => {
                alert("Email verification alrady been sent!");
              });
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorMessage);
          if (error.code === "auth/user-not-found") {
            alert("No user found with this email");
          } else {
            alert(
              errorCode === "auth/wrong-password" ? "Wrong password" : errorCode
            );
          }
        });
    } else {
      alert("Please fill both fields");
    }
  }
  function handleClickLogin() {
    handleLogin();
  }
  return (
    <section
      style={{
        marginTop: 50,
      }}
      className="pd-top-100 pd-bottom-100 bg-sky-blue"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-10 text-center">
            <div className="sign-in-area">
              <h2>Sign in to your account</h2>
              <div className="contact-form-wrap">
                <div className="single-input-wrap input-group">
                  <label htmlFor="inp-1">Email address</label>
                  <input
                    style={{
                      color: "black",
                    }}
                    required={true}
                    id="inp-1"
                    type="email"
                    className="form-control"
                    value={details.email}
                    onChange={(e) => {
                      setDetails({ ...details, email: e.target.value });
                    }}
                    placeholder="Enter your email address"
                  />
                </div>
                <div className="single-input-wrap input-group">
                  <label htmlFor="inp-2">Password</label>
                  <input
                    style={{
                      color: "black",
                    }}
                    required={true}
                    id="inp-2"
                    type="password"
                    value={details.password}
                    onChange={(e) => {
                      setDetails({ ...details, password: e.target.value });
                    }}
                    className="form-control"
                    placeholder="Enter your email password"
                  />
                </div>
                <div className="row">
                  {/* <div className="col-lg-6 col-sm-6 col-12">
                    <div className="single-category text-left">
                      <label>
                        <input
                          type="checkbox"
                          aria-label="Checkbox for following text input"
                        />
                        <span className="checkmark" />
                        Remember me
                      </label>
                    </div>
                  </div> */}
                  <div
                    onClick={() => {
                      history.push("/forgot-password");
                    }}
                    className="col-lg-6 col-sm-6 col-12 text-sm-right text-left"
                  >
                    <a className="forget-pass">Forgot your password?</a>
                  </div>
                </div>
                <button
                  onClick={handleClickLogin}
                  className="btn btn-base w-100"
                >
                  Sign In
                </button>
                <p>Or continue with</p>
                <button
                  onClick={() => {
                    signInWithPopup(auth, provider)
                      .then(async (result) => {
                        // This gives you a Google Access Token. You can use it to access the Google API.
                        const credential =
                          GoogleAuthProvider.credentialFromResult(result);
                        const token = credential.accessToken;
                        // The signed-in user info.
                        const user = result.user;
                        addUserFirestore(user);
                        // createStripeCustomer(user);
                        dispatch(login(user));
                        history.push("/");
                        // IdP data available using getAdditionalUserInfo(result)
                        // ...
                      })
                      .catch((error) => {
                        // Handle Errors here.
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        // The email of the user's account used.
                        const email = error.customData.email;
                        // The AuthCredential type that was used.
                        const credential =
                          GoogleAuthProvider.credentialFromError(error);
                        // ...
                      });
                  }}
                  className="btn btn-g w-100"
                >
                  <i className="fab fa-google" />
                  Continue with Google
                </button>
                <button
                  onClick={() => {
                    signInWithPopup(auth, fbProvider)
                      .then(async (result) => {
                        // The signed-in user info.
                        const user = result.user;
                        dispatch(login(user));
                        addUserFirestore(user);
                        // createStripeCustomer(user);
                        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                        const credential =
                          FacebookAuthProvider.credentialFromResult(result);
                        const accessToken = credential.accessToken;
                        history.push("/");
                        // IdP data available using getAdditionalUserInfo(result)
                        // ...
                      })
                      .catch((error) => {
                        // Handle Errors here.
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        // The email of the user's account used.
                        const email = error.customData.email;
                        // The AuthCredential type that was used.
                        const credential =
                          FacebookAuthProvider.credentialFromError(error);

                        // ...
                      });
                  }}
                  className="btn btn-f w-100"
                >
                  <i className="fab fa-facebook-f" />
                  Continue with Facebook
                </button>
                <p>
                  Need an account? <Link to="/sign-up">Sign Up</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
