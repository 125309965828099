import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import HomeV1 from "./components/home-v1";
import Product from "./components/product";
import ProductDetails from "./components/product-details";
import Vendor from "./components/vendor";
import Category from "./components/category";
import Faq from "./components/faq";
import policy from "./components/policy";
import Error from "./components/error";
import SignIn from "./components/sign-in";
import SignUp from "./components/sign-up";
import Contact from "./components/contact";
import Blog from "./components/blog";
import BlogDetails from "./components/blog-details";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "./redux/slices/auth/authSlice";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import Loader from "./components/loader";
import ForgotPassword from "./components/forgot-password";
import Cancel from "./components/cancel";
import Success from "./components/success";
export default function Routes() {
  const { loggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    document.body.style.zoom = "80%";
    const unsubscibe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user
          .getIdToken()
          .then((tokenResult) => {
            const token = tokenResult.token;
            // Use the identity token for authentication purposes
            console.log(tokenResult);
          })
          .catch((error) => {
            // Handle errors
            console.error(error);
          });
        dispatch(login(user));
        setLoading(false);
      } else {
        dispatch(logout());
        setLoading(false);
      }
    });
    return () => {
      unsubscibe();
    };
  }, []);
  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <Switch>
        <Route exact path="/" component={HomeV1} />
        <Route path="/product" component={Product} />
        <Route path="/product-details" component={ProductDetails} />
        <Route path="/vendor" component={Vendor} />
        <Route path="/category" component={Category} />
        <Route path="/faq" component={Faq} />
        <Route path="/policy" component={policy} />
        <Route path="/error" compone21nt={Error} />
        <Route path="/error" component={Error} />
        <Route path="/cancel" component={Cancel} />
        <Route path="/success" component={Success} />
        <Route path="/contact" component={Contact} />
        <Route path="/forgot-password" component={ForgotPassword} />
        {loggedIn ? null : (
          <>
            <Route path="/sign-in" component={SignIn} />
            <Route path="/sign-up" component={SignUp} />
          </>
        )}
        <Route path="/blog" component={Blog} />
        <Route path="/profile" component={BlogDetails} />
        <Route path="*" component={Error} />
      </Switch>
    </div>
  );
}
