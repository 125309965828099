import axios from "axios";
import { baseURL, localURL } from "./constants";

export function removeIPfromDb(uid, prevIP) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${baseURL}/removeIP?uid=${uid}&prevIP=${prevIP}`
      );
      const data = response.data;
      console.log(data);
      // Handle the response data
      resolve(data);
    } catch (error) {
      console.error(error);
      reject(error);
      // Handle errors
    }
  });
}
