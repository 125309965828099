import axios from "axios";
import { baseURL, localURL } from "./constants";

export function addIptoDb(uid) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${baseURL}/addIP?uid=${uid}`);
      const data = response.data;
      console.log(data);
      // Handle the response data
      resolve(data);
    } catch (error) {
      console.error(error);
      reject(error);
      // Handle errors
    }
  });
}
