import React, { Component } from "react";
import { Link, useHistory } from "react-router-dom";
import parse from "html-react-parser";
import { baseURL } from "../../helpers/constants";
function Error({ type }) {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const history = useHistory();
  // Get the session ID from the URL query parameter
  const hashParams = new URLSearchParams(window.location.hash.slice(1));
  const sessionId = hashParams.get("session_id");
  function acknowldge() {
    if (sessionId) {
      storeSubscriptionDetails(sessionId);
    } else {
      console.error("Session ID not found in the URL");
    }
  }

  async function storeSubscriptionDetails(sessionId) {
    return fetch(`${baseURL}/checkout-success`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sessionId }),
    })
      .then((response) => {
        if (response.ok) {
          history.push("/");
          return response.json();
        } else {
          throw new Error("Failed to store subscription details");
        }
      })
      .then((data) => {
        console.log("Subscription details stored:", data);
      })
      .catch((error) => {
        console.error("Error storing subscription details:", error);
      });
  }

  return (
    <section className="error-page-area pd-top-100 pd-bottom-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="error-inner text-center">
              {type === "error" ? (
                <img src={publicUrl + "assets/img/404.png"} alt="img" />
              ) : null}
              {type === "cancel" ? (
                <>
                  <h2 style={{ marginTop: 50 }}>Oops!</h2>
                  <p>You canceled the subscription payment process.</p>
                </>
              ) : type === "success" ? (
                <>
                  <h2 style={{ marginTop: 50 }}>Thanks!</h2>
                  <p>You purchase our subscription</p>
                </>
              ) : null}
              {type === "success" ? (
                <a
                  onClick={acknowldge}
                  style={{ color: "white" }}
                  className="btn btn-base"
                >
                  Acknowledge
                </a>
              ) : (
                <Link className="btn btn-base" to="/">
                  Go Home
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Error;
