import { baseURL, localURL } from "./constants";

export const fetchSubscriptionStatus = (customerID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${baseURL}/check-subscription/${customerID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        resolve(data);
      } else {
        reject("Failed to fetch subscription status");
      }
    } catch (error) {
      reject("Error fetching subscription status:", error);
    }
  });
};
