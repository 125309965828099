import React from "react";
import Navbar from "./global-components/navbar-v2";
import PageHeader from "./global-components/page-header";
import ForgotPasswordPage from "./section-components/forgot-password";
import Footer from "./global-components/footer";

const ForgotPassword = () => {
  return (
    <div>
      <Navbar />
      {/* <PageHeader headertitle="Sign In" /> */}
      <ForgotPasswordPage />
      <Footer />
    </div>
  );
};

export default ForgotPassword;
