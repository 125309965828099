import React, { useEffect, useState } from "react";
import Navbar from "./global-components/navbar";
import Banner from "./section-components/banner";
import Featured from "./section-components/featured";
import ProductV1 from "./section-components/product-v1";
import About from "./section-components/about";
import Pricing from "./section-components/pricing";
import FunFact from "./section-components/funfact";
import Testimonial from "./section-components/testimonial";
import LatestPost from "./blog-components/latest-news";
import Footer from "./global-components/footer";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

const Home_V1 = () => {
  //   const [products, setProducts] = useState([]);
  //   useEffect(() => {
  //     const fetchProducts = async () => {
  //       const productsCollection = collection(db, "products");
  //       const snapshot = await getDocs(productsCollection);
  //       const productData = snapshot.docs.map((doc) => doc.data());
  //       console.log(productData);
  //       setProducts(productData);
  //     };

  //     // Fetch products initially
  //     fetchProducts();

  //     // Listen for real-time updates
  //     // const unsubscribe = onSnapshot(productsCollection, (snapshot) => {
  //     //   const productData = snapshot.docs.map((doc) => doc.data());
  //     //   setProducts(productData);
  //     // });

  //     // // Clean up the listener when the component unmounts
  //     // return () => unsubscribe();
  //   }, []);
  return (
    <div>
      <Navbar />
      <Banner />
      <Featured />
      <ProductV1 />

      <About />
      <Pricing />
      <FunFact />
      <Testimonial />
      <LatestPost />
      <Footer />
    </div>
  );
};

export default Home_V1;
