import React from "react";
import Navbar from "./global-components/navbar-v2";
import PageHeader from "./global-components/page-header";
import ErrorPage from "./section-components/error";
import Footer from "./global-components/footer";

const Cancel = () => {
  return (
    <div>
      <Navbar />

      <ErrorPage type={"cancel"} />
      <Footer />
    </div>
  );
};

export default Cancel;
