import React from "react";

const IPAddress = ({ showUpdate = true, onPress, currentIP, registeredIP }) => {
  return (
    <div
      style={{
        width: "96%",
        border: "1px solid #DCDCDC",
        backgroundColor: "white",
        marginBottom: 10,
        alignSelf: "center",
        marginLeft: "15px",
      }}
    >
      <div
        style={{
          width: "100%",
          borderBottom: "1px solid #DCDCDC",
          backgroundColor: "#F5F5F5",
        }}
      >
        <h5
          style={{
            padding: "10px",
            fontWeight: "bold",
            fontSize: "16px",
          }}
        >
          IP Address Status
        </h5>
      </div>
      <p style={{ padding: "10px", marginTop: "10px", fontSize: "14px" }}>
        Your myTVPassport subscription is tied to your IP address. Your IP
        address may change from time to time. If this happens, you can register
        your new IP address below.
      </p>
      <div
        style={{
          display: "flex",
          width: "80%",
          padding: "10px",
          marginTop: "10px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <strong style={{ fontSize: "16px" }}>Registered IP:</strong>
        <span style={{ fontSize: "16px" }}>{registeredIP}</span>
      </div>
      <div
        style={{
          display: "flex",
          width: "80%",
          padding: "10px",
          marginTop: "10px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <strong style={{ fontSize: "16px" }}>Current IP:</strong>
        <span style={{ fontSize: "16px" }}>{currentIP}</span>
      </div>
      <div
        style={{
          width: "95%",
          marginLeft: "5px",
          //  border: "1px solid #DCDCDC",
          marginBottom: "10px",
        }}
      >
        {showUpdate ? (
          <p style={{ padding: "5px", fontSize: "14px" }}>
            It looks like your<strong> Registered IP </strong> and{" "}
            <strong>Current IP </strong> are different. You won't be able to
            unblock any services from your Current IP Address. Click the button
            below to update your Registered IP with your Current IP. After you
            do this, you won't be able to unblock any services from your old
            Registered IP Address.
          </p>
        ) : null}
        {showUpdate ? (
          <button
            onClick={() => onPress()}
            style={{
              fontSize: "14px",
              borderRadius: "5px",
              height: "45px",
              lineHeight: "45px",
              marginLeft: 5,
              marginBottom: "10px",
            }}
            className="btn btn-base"
          >
            Update Registered IP
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default IPAddress;
