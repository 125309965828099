import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithPopup,
  FacebookAuthProvider,
  sendEmailVerification,
} from "firebase/auth";
import { auth, db } from "../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { addUserFirestore } from "../../helpers/addUserFirestore";
import axios from "axios";
import { createStripeCustomer } from "../../helpers/createStripeCustomer";
const provider = new GoogleAuthProvider();
const fbProvider = new FacebookAuthProvider();
function SignUp() {
  const history = useHistory();
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [details, setDetails] = useState({
    email: "",
    password: "",
  });
  async function handleSignUp() {
    if (details.email !== "" && details.password !== "") {
      createUserWithEmailAndPassword(auth, details.email, details.password)
        .then(async (userCredential) => {
          // Signed in
          const user = userCredential.user;
          if (user.emailVerified) {
            addUserFirestore(user);
            createStripeCustomer(user);
            history.push("/");
          } else {
            sendEmailVerification(auth.currentUser)
              .then((res) => {
                console.log(res);
                alert(
                  `Verification email has been sent to you.\nPlease check your email inbox.`
                );
              })
              .catch((er) => {
                alert("Email verification alrady been sent!");
              });
          }

          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          alert(
            errorCode === "auth/email-already-in-use"
              ? "User already exist with this email"
              : errorCode
          );
        });
    } else {
      alert("Please fill both fields");
    }
  }
  async function checkEmail() {
    const kickbox_api_key =
      "live_540abf9f4d4ceb482d3ee6b40a4f8b9ad6878689563ec8eba9dbd0f67e5bf357";
    const kickbox_url = `https://open.kickbox.com/v1/disposable/${encodeURIComponent(
      details.email
    )}`;

    // Make a GET request to Kickbox API to check if email is disposable
    return axios
      .get(kickbox_url)
      .then((response) => {
        const is_disposable = response.data.disposable;

        if (is_disposable) {
          // Email is disposable, reject the creation of the user document
          alert("Email is disposable");
        } else {
          // Email is not disposable, allow the creation of the user document
          handleSignUp();
        }
      })
      .catch((error) => {
        console.error(error);
        return;
      });
  }
  return (
    <section
      style={{
        marginTop: 50,
      }}
      className="pd-top-100 pd-bottom-100 bg-sky-blue"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-10 text-center">
            <div className="sign-in-area">
              <h2>Sign up to your account</h2>
              <div className="contact-form-wrap">
                <div className="single-input-wrap input-group">
                  <label htmlFor="inp-1">Email address</label>
                  <input
                    style={{
                      color: "black",
                    }}
                    id="inp-1"
                    type="email"
                    required
                    value={details.email}
                    onChange={(e) => {
                      setDetails({ ...details, email: e.target.value });
                    }}
                    className="form-control"
                    placeholder="Enter your email address"
                  />
                </div>
                <div className="single-input-wrap input-group">
                  <label htmlFor="inp-2">Password</label>
                  <input
                    style={{
                      color: "black",
                    }}
                    id="inp-2"
                    type="password"
                    required
                    value={details.password}
                    onChange={(e) => {
                      setDetails({ ...details, password: e.target.value });
                    }}
                    className="form-control"
                    placeholder="Enter your email password"
                  />
                </div>
                {/* <div className="row">
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="single-category text-left">
                        <label>
                          <input
                            type="checkbox"
                            aria-label="Checkbox for following text input"
                          />
                          <span className="checkmark" />
                          Remember me
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-12 text-sm-right text-left">
                      <a className="forget-pass" href="#">
                        Forgot your password?
                      </a>
                    </div>
                  </div> */}
                <button onClick={checkEmail} className="btn btn-base w-100">
                  Sign Up
                </button>
                <p>Or continue with</p>
                <button
                  onClick={() => {
                    signInWithPopup(auth, provider)
                      .then(async (result) => {
                        // This gives you a Google Access Token. You can use it to access the Google API.
                        const credential =
                          GoogleAuthProvider.credentialFromResult(result);
                        const token = credential.accessToken;
                        // The signed-in user info.
                        const user = result.user;
                        addUserFirestore(user);
                        createStripeCustomer(user);
                        history.push("/");
                        // IdP data available using getAdditionalUserInfo(result)
                        // ...
                      })
                      .catch((error) => {
                        // Handle Errors here.
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        // The email of the user's account used.
                        const email = error.customData.email;
                        // The AuthCredential type that was used.
                        const credential =
                          GoogleAuthProvider.credentialFromError(error);
                        // ...
                      });
                  }}
                  className="btn btn-g w-100"
                >
                  <i className="fab fa-google" />
                  Continue with Google
                </button>
                <button
                  onClick={() => {
                    signInWithPopup(auth, fbProvider)
                      .then(async (result) => {
                        // The signed-in user info.
                        const user = result.user;
                        addUserFirestore(user);
                        createStripeCustomer(user);
                        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                        const credential =
                          FacebookAuthProvider.credentialFromResult(result);
                        const accessToken = credential.accessToken;
                        history.push("/");
                        // IdP data available using getAdditionalUserInfo(result)
                        // ...
                      })
                      .catch((error) => {
                        // Handle Errors here.
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        // The email of the user's account used.
                        const email = error.customData.email;
                        // The AuthCredential type that was used.
                        const credential =
                          FacebookAuthProvider.credentialFromError(error);

                        // ...
                      });
                  }}
                  className="btn btn-f w-100"
                >
                  <i className="fab fa-facebook-f" />
                  Continue with Facebook
                </button>
                <p>
                  Already Have account? <Link to="/sign-in">Sign In</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignUp;
