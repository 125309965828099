import { baseURL } from "./constants";

export function createStripeCustomer(user) {
  return new Promise((resolve, reject) => {
    fetch(`${baseURL}/create-customer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: user?.email,
        name: user.displayName == null ? "No Name" : user.displayName,
        uid: user.uid,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.log("Failed to create customer", response.statusText);
        }
      })
      .then((session) => {
        console.log("create customer", session);
        resolve(session);
      })
      .catch((error) => {
        console.error("Error creating customer:", error);
        reject(error);
      });
  });
}
