import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getIdToken } from "firebase/auth";
import { fetchUser } from "../../helpers/fetchUser";
import { baseURL } from "../../helpers/constants";

function ProductPage() {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [products, setProducts] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubscriber, setisSubscriber] = useState(false);
  async function fetchAllProducts() {
    const response = await fetch(`${baseURL}/products`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      const data = await response.json();
      if (data) {
        setProducts(data);
      }
    }
    setLoading(false);
    // const productsRef = collection(db, "products");
    // const productsSnapshot = await getDocs(productsRef);

    // const products = [];

    // for (const productDoc of productsSnapshot.docs) {
    //   const productData = productDoc.data();
    //   const productId = productDoc.id;

    //   const pricesRef = collection(productDoc.ref, "prices");
    //   const pricesSnapshot = await getDocs(pricesRef);

    //   const productWithPrices = {
    //     id: productId,
    //     ...productData,
    //     prices: pricesSnapshot.docs.map((priceDoc) => priceDoc.data()),
    //   };

    //   products.push(productWithPrices);
    // }

    // return products;
  }
  useEffect(() => {
    fetchAllProducts();
    fetchUser(user)
      .then((res) => {
        console.log(res);
        setCurrentUser(res);
        setisSubscriber(res.subscriptionStatus.hasSubscription);
      })
      .catch((er) => console.log("er", er));

    // fetchAllProducts()
    //   .then((products) => {
    //     console.log("All products:", products);
    //     setProducts(products);
    //     setLoading(false);
    //     // Set the state with the products data
    //     // this.setState({ products });
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching products:", error);
    //   });
  }, []);
  function unitPrice(unitAmount) {
    if (unitAmount) {
      return (unitAmount / 100).toFixed(2);
    } else {
      return 0;
    }
  }
  const createCheckoutSession = async (productId) => {
    // Make a request to your server to create the checkout session
    const response = await fetch(`${baseURL}/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lookup_key: productId,
        email: user?.email,
        name: user?.name,
        uid: user?.uid,
        docId: user?.uid,
      }),
    });
    if (response.ok) {
      const session = await response.json();
      if (session) {
        window.location.href = session.url;
        console.log(session.url);
      }
    }
  };
  const createPortalSession = async (customer_id) => {
    // Make a request to your server to create the checkout session
    const response = await fetch(`${baseURL}/create-portal-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_id: customer_id,
      }),
    });
    if (response.ok) {
      const session = await response.json();
      if (session) {
        window.location.href = session.url;
        console.log(session.url);
      }
    }
  };

  const history = useHistory();

  // async function checkDomain() {
  //   getIdToken(user)
  //     .then((res) => {
  //       fetch(
  //         "https://us-central1-tvpassport-65eb2.cloudfunctions.net/app/check-domain?domain=abc.com",
  //         {
  //           method: "GET",
  //           headers: {
  //             Authorization: `bearer ${res}`,
  //             "Content-Type": "application/json",
  //           },
  //           // Other request options
  //         }
  //       )
  //         .then((response) => {
  //           // Handle the response
  //           console.log(response);
  //         })
  //         .catch((error) => {
  //           // Handle errors
  //           console.log(error);
  //         });
  //     })
  //     .catch((er) => console.log("errri", er));
  // }
  return (
    <section className="all-item-area all-item-area-2 pd-top-100 pd-bottom-100">
      <div className="container">
        {/* <div className="header-shadow-inner">
          <div className="row">
            <div className="col-lg-9 align-self-center">
              <div className="isotope-filters item-isotope-btn">
                <button className="button active ml-0" data-filter="*">
                  All Items
                </button>
                <button className="button" data-filter=".cat-1">
                  Site Templates
                </button>
                <button className="button" data-filter=".cat-2">
                  UI Templates
                </button>
                <button className="button" data-filter=".cat-3">
                  Cms Items
                </button>
              </div>
            </div>
            <div className="col-lg-3">
              <form className="single-select-inner float-lg-right">
                <select>
                  <option>All resources</option>
                  <option value={1}>1 resource</option>
                  <option value={2}>2 resource</option>
                  <option value={3}>3 resource</option>
                </select>
              </form>
            </div>
          </div>
        </div> */}
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="all-item-section go-top">
            <div className="item-isotope row">
              <div className="item-sizer" />
              {/* gallery item start here */}
              {isSubscriber ? (
                <div>
                  <h5>You have already subscribed.</h5>
                  <a
                    onClick={() =>
                      createPortalSession(currentUser?.stripeCustomerId)
                    }
                    style={{ color: "white" }}
                    className="btn btn-base"
                  >
                    Manage Subscription
                  </a>
                </div>
              ) : (
                <>
                  {products.map((item, index) => {
                    return (
                      <div
                        className={`all-isotope-item col-lg-4 col-sm-6 cat-1`}
                      >
                        <div className="thumb">
                          <a className="gallery-fancybox">
                            <img
                              src={
                                item?.images[0]
                                  ? item?.images[0]
                                  : publicUrl + "assets/img/item/7.png"
                              }
                              alt="image"
                            />
                          </a>
                          <a
                            onClick={() => {
                              if (user) {
                                createCheckoutSession(item?.default_price);
                                //fetchSubscriptionStatus("cus_NsnRPiZZWArQVC");
                              } else {
                                history.push("/sign-in");
                              }
                            }}
                            className="btn btn-white"
                          >
                            Buy this
                          </a>
                        </div>
                        <div className="item-details">
                          <h4>
                            <Link to="/product-details">{item?.name}</Link>
                          </h4>
                          <p>{item?.description}</p>
                          {/* <span className="ratting">
           <i className="fa fa-star" />
           <i className="fa fa-star" />
           <i className="fa fa-star" />
           <i className="fa fa-star" />
           <i className="fa fa-star star-o" />
           <span>(12)</span>
         </span> */}
                          <a
                            href="#"
                            className="author-details align-item-center"
                          >
                            {/* <img
             src={publicUrl + "assets/img/author/1.png"}
             alt="img"
           /> */}
                            <span>{item?.name}</span>
                          </a>
                          <span className="price bg-white float-right">
                            € {item.price}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}

              {/* <div className="all-isotope-item col-lg-4 col-sm-6 cat-1">
              <div className="thumb">
                <a className="gallery-fancybox" href="#">
                  <img src={publicUrl + "assets/img/item/8.png"} alt="image" />
                </a>
                <a className="btn btn-white" href="#">
                  Live Preview
                </a>
              </div>
              <div className="item-details">
                <h4>
                  <Link to="/product-details">Case Study</Link>
                </h4>
                <p>Photography Word Press Theme</p>
                <span className="ratting">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star star-o" />
                  <span>(12)</span>
                </span>
                <a href="#" className="author-details align-item-center">
                  <img src={publicUrl + "assets/img/author/2.png"} alt="img" />
                  <span>Clay J. Barfield</span>
                </a>
                <span className="price bg-white float-right">$30.00</span>
              </div>
            </div>
            <div className="all-isotope-item col-lg-4 col-sm-6 cat-3 cat-2">
              <div className="thumb">
                <a className="gallery-fancybox" href="#">
                  <img src={publicUrl + "assets/img/item/9.png"} alt="image" />
                </a>
                <a className="btn btn-white" href="#">
                  Live Preview
                </a>
              </div>
              <div className="item-details">
                <h4>
                  <Link to="/product-details">Project Collaboration</Link>
                </h4>
                <p>Photography Word Press Theme</p>
                <span className="ratting">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star star-o" />
                  <span>(12)</span>
                </span>
                <a href="#" className="author-details align-item-center">
                  <img src={publicUrl + "assets/img/author/3.png"} alt="img" />
                  <span>Duane A. Oliva</span>
                </a>
                <span className="price bg-white float-right">$30.00</span>
              </div>
            </div>
            <div className="all-isotope-item col-lg-4 col-sm-6 cat-3 cat-1">
              <div className="thumb">
                <a className="gallery-fancybox" href="#">
                  <img src={publicUrl + "assets/img/item/10.png"} alt="image" />
                </a>
                <a className="btn btn-white" href="#">
                  Live Preview
                </a>
              </div>
              <div className="item-details">
                <h4>
                  <Link to="/product-details">Voice Landing Page</Link>
                </h4>
                <p>Photography Word Press Theme</p>
                <span className="ratting">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star star-o" />
                  <span>(12)</span>
                </span>
                <a href="#" className="author-details align-item-center">
                  <img src={publicUrl + "assets/img/author/4.png"} alt="img" />
                  <span>Cynthia V. Sylvia</span>
                </a>
                <span className="price bg-white float-right">$30.00</span>
              </div>
            </div>
            <div className="all-isotope-item col-lg-4 col-sm-6 cat-2 cat-1">
              <div className="thumb">
                <a className="gallery-fancybox" href="#">
                  <img src={publicUrl + "assets/img/item/11.png"} alt="image" />
                </a>
                <a className="btn btn-white" href="#">
                  Live Preview
                </a>
              </div>
              <div className="item-details">
                <h4>
                  <Link to="/product-details">Clean Dashboard</Link>
                </h4>
                <p>Photography Word Press Theme</p>
                <span className="ratting">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star star-o" />
                  <span>(12)</span>
                </span>
                <a href="#" className="author-details align-item-center">
                  <img src={publicUrl + "assets/img/author/5.png"} alt="img" />
                  <span>Paul E. Hensley</span>
                </a>
                <span className="price bg-white float-right">$30.00</span>
              </div>
            </div>
            <div className="all-isotope-item col-lg-4 col-sm-6 cat-3 cat-2">
              <div className="thumb">
                <a className="gallery-fancybox" href="#">
                  <img src={publicUrl + "assets/img/item/12.png"} alt="image" />
                </a>
                <a className="btn btn-white" href="#">
                  Live Preview
                </a>
              </div>
              <div className="item-details">
                <h4>
                  <Link to="/product-details">Website Redesign</Link>
                </h4>
                <p>Photography Word Press Theme</p>
                <span className="ratting">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star star-o" />
                  <span>(12)</span>
                </span>
                <a href="#" className="author-details align-item-center">
                  <img src={publicUrl + "assets/img/author/6.png"} alt="img" />
                  <span>Frances R. Leming</span>
                </a>
                <span className="price bg-white float-right">$30.00</span>
              </div>
            </div> */}
            </div>
          </div>
        )}
        {/* <div className="row">
          <div className="col-lg-12 text-center">
            <ul className="pagination pagination-2">
              <li className="page-item">
                <a className="page-link" href="#">
                  <i className="la la-angle-left" />
                </a>
              </li>
              <li className="page-item active">
                <a className="page-link" href="#">
                  01
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  02
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  03
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  <i className="la la-angle-right" />
                </a>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default ProductPage;
