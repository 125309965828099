import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { createStripeCustomer } from "./createStripeCustomer";
const publicIp = require("react-public-ip");
export const addUserFirestore = async (user) => {
  try {
    const ipv4 = (await publicIp.v4()) || "";
    const ipv6 = (await publicIp.v6()) || "";
    const collectionRef = collection(db, "users");
    const userRef = doc(collectionRef, user.uid);
    createStripeCustomer(user).then((customer) => {
      getDoc(userRef)
        .then(async (docSnapshot) => {
          if (docSnapshot.exists()) {
            // Document exists
            console.log("Already Exists");
          } else {
            const docRef = await setDoc(doc(db, "users", user.uid), {
              user: {
                uid: user.uid,
                name: user.displayName == null ? "No Name" : user.displayName,
                email: user.email,
                isVetified: user.emailVerified,
                phoneNumber: user.phoneNumber,
                photoURL: user.photoURL,
                createdAt: serverTimestamp(),
                publicIPV4Address: ipv4,
                publicIPV6Address: ipv6,
                active: true,
                type: "user",
                stripeCustomerId: customer?.id,
                subscription: {
                  active: false,
                  startDate: null,
                  endDate: null,
                },
              },
            });
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};
