import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { DNSResolver } from "dns.js";
import { db } from "../../firebase";
import { getIp } from "../../helpers/getIP";
import { addIptoDb } from "../../helpers/addIPtoDb";
import { removeIPfromDb } from "../../helpers/removeIPfromDb";
import { fetchSubscriptionStatus } from "../../helpers/checkSubscriptionStatus";
import IPAddress from "./ip-address";

function BlogDetails() {
  const { user } = useSelector((state) => state.auth);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [isSubscriber, setisSubscriber] = useState(false);

  let publicUrl = process.env.PUBLIC_URL + "/";

  const [ip, setIp] = useState({
    ipv4: "Getting Your IP...",
    ipv6: "",
  });
  useEffect(() => {
    getIp("v4")
      .then((res) => {
        setIp({ ...ip, ipv4: res });
      })
      .catch((er) => {
        console.log(er);
      });
  }, []);

  const getData = async () => {
    fetch("https://api.ipify.org").then((res) => {
      console.log(res);
    });
  };

  const fetchUser = async () => {
    try {
      const collectionRef = collection(db, "users");
      const userRef = doc(collectionRef, user.uid);
      const unsubscribe = onSnapshot(
        userRef,
        (docSnapshot) => {
          setCurrentUser(docSnapshot.data().user);
          setName(
            docSnapshot.data().user.name === "No Name"
              ? ""
              : docSnapshot.data().user.name
          );
          setLoading(false);
          fetchSubscriptionStatus(docSnapshot.data().user?.stripeCustomerId)
            .then((res) => {
              setisSubscriber(res.hasSubscription);
            })
            .catch((er) => alert(er));
        },
        (error) => {
          console.log(error);
        }
      );
      return unsubscribe;
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUser();
  }, []);
  const handleUpdateIpAddress = async () => {
    try {
      const collectionRef = collection(db, "users");
      const userRef = doc(collectionRef, user.uid);
      currentUser.publicIPV4Address = ip.ipv4;
      currentUser.publicIPV6Address = ip.ipv6;
      updateDoc(userRef, {
        user: currentUser,
      })
        .then((res) => {
          removeIPfromDb(user?.uid, currentUser.publicIPV4Address)
            .then((res) => {
              console.log("removed from mysql");
              addIptoDb(user.uid)
                .then((res) => {
                  console.log("added in mysql db");
                })
                .catch((er) => {
                  console.log("err adding in update mysql", er);
                });
            })
            .catch((er) => {
              console.log("err removing from mysql");
            });
        })
        .catch((er) => {
          console.log(er);
        });
      console.log("IP address updated successfully!");
    } catch (error) {
      console.error("Error updating IP address:", error);
    }
  };
  const handleUpdateName = async () => {
    console.log(name);
    if (name !== "") {
      try {
        const collectionRef = collection(db, "users");
        const userRef = doc(collectionRef, user.uid);
        currentUser.name = name;

        updateDoc(userRef, {
          user: currentUser,
        })
          .then((res) => {
            console.log("res", res);
          })
          .catch((er) => {
            console.log(er);
          });
        console.log("IP address updated successfully!");
      } catch (error) {
        console.error("Error updating IP address:", error);
      }
    } else {
      alert("Name must not be empty");
    }
  };
  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const handleDragStart = (event) => {
    event.preventDefault();
  };

  return (
    <section className="blog-page-area pd-top-100 pd-bottom-100">
      <div className="container">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="row">
            <div className="col-lg-4">
              <div className="sidebar-area">
                <div className="widget widget-author widget-border text-center">
                  <div className="thumb">
                    <img
                      src={
                        user?.photoURL
                          ? user.photoURL
                          : publicUrl + "assets/img/widget/4.png"
                      }
                      alt="img"
                    />
                  </div>
                  <div className="author-details">
                    <h4>{currentUser?.name ? currentUser?.name : "No Name"}</h4>
                    <span>{currentUser?.email}</span>
                    <p>
                      Vero eos et accusamus et iustoys odio dignissimos ducimu
                      blanditiis praesentium voluptatum
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="blog-details-page-inner">
                <div className="single-blog-inner m-0">
                  <div className="comment-form">
                    <div className="section-title">
                      <h3>Personal Information</h3>
                    </div>
                    <div className="contact-form-wrap">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="single-input-wrap input-group">
                            <input
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                              value={name}
                              type="text"
                              className="form-control"
                              placeholder="Your Full Name"
                            />
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="far fa-user" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="single-input-wrap input-group">
                            <input
                              disabled
                              value={user?.email}
                              type="text"
                              className="form-control"
                              placeholder="Your Email"
                            />
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="far fa-envelope" />
                              </div>
                            </div>
                          </div>
                        </div>
                        {isSubscriber ? (
                          <>
                            <IPAddress
                              showUpdate={
                                ip.ipv4 !== currentUser?.publicIPV4Address
                              }
                              currentIP={ip.ipv4}
                              registeredIP={currentUser?.publicIPV4Address}
                              onPress={() => handleUpdateIpAddress()}
                            />
                            {/* <div className="col-lg-6 col-md-6 col-12">
                              <div className="section-title">
                                <h3>DNS Settings</h3>
                              </div>
                            </div>
                            <div className="single-input-wrap input-group">
                              <textarea
                                disabled
                                value={
                                  ip.ipv4 !== currentUser?.publicIPV4Address
                                    ? `Your current IP Address is ${ip.ipv4} and in our server we have ${currentUser?.publicIPV4Address}`
                                    : `In our server your IP Address is ${currentUser?.publicIPV4Address}`
                                }
                                type="text"
                                className="form-control"
                                placeholder="Getting Ip Address..."
                                onMouseDown={handleMouseDown}
                                onDragStart={handleDragStart}
                              />
                              {currentUser?.publicIPV4Address ===
                              ip.ipv4 ? null : (
                                <div
                                  onClick={async () => {
                                    handleUpdateIpAddress();
                                    //  handleCheckDomain();
                                  }}
                                  className="input-group-prepend"
                                >
                                  <div className="input-group-text">
                                    <i alt="Reload" class="fas fa-sync-alt" />
                                  </div>
                                </div>
                              )}
                            </div> */}
                          </>
                        ) : // <IPAddress />
                        null}

                        {/* <button type="submit" className="btn btn-base">
                          Update your IP Address
                        </button> */}

                        <div className="col-12">
                          <div className="submit-area">
                            <button
                              onClick={async () => {
                                // fetch(
                                //   `http://localhost:4000/addIP?uid=${user?.uid}`,
                                //   {
                                //     method: "POST",
                                //   }
                                // )
                                //   .then((res) => {
                                //     console.log(res);
                                //   })
                                //   .catch((er) => console.log(er));
                                handleUpdateName();
                                //handleCheckDomain();
                                // console.log(currentUser?.stripeCustomerId);
                                // addIptoDb(user?.uid);
                              }}
                              className="btn btn-base"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default BlogDetails;
