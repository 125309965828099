import { collection, doc, getDoc } from "firebase/firestore";
import { fetchSubscriptionStatus } from "./checkSubscriptionStatus";
import { db } from "../firebase";

export const fetchUser = async (user) => {
  try {
    const collectionRef = collection(db, "users");
    const userRef = doc(collectionRef, user.uid);
    const userSnapshot = await getDoc(userRef);
    const userData = userSnapshot.data().user;
    const subscriptionStatus = await fetchSubscriptionStatus(
      userData?.stripeCustomerId
    );
    return { ...userData, subscriptionStatus };
  } catch (error) {
    throw new Error("Failed to fetch user: " + error);
  }
};
